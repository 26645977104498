<template>
  <div @click="contact()">
    <v-icon
      v-if="left"
      v-show="!noicon"
      class="greenwrap-left pa-2"
      color="white"
      size="40"
      >mdi-whatsapp</v-icon
    >
    <v-btn
      color="error darken-1"
      class="pa-3"
      rounded
      :large="!$vuetify.breakpoint.mobile"
    >
      <span class="font-weight-black" style="font-family: 'Roboto'"
        >Fale Conosco</span
      >
    </v-btn>
    <v-icon
      v-if="!left"
      v-show="!noicon"
      class="greenwrap pa-2"
      color="white"
      size="30"
      >mdi-whatsapp</v-icon
    >
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    noicon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    contact() {
      if (this.$vuetify.breakpoint.mobile) {
        window.open("whatsapp://send?phone=5541995450522");
      } else {
        window.open(
          "https://web.whatsapp.com/send?phone=5541995450522",
          "_blank"
        );
      }
    },
  },
};
</script>
<style>
.greenwrap-left {
  z-index: 1;
  left: 5px;
  background-color: green;
  border-radius: 50px;
}

.greenwrap {
  top: -30px;
  left: -20px;
  background-color: green;
  border-radius: 50px;
}
</style>
