<template>
  <div>
    <v-card dark :img="require('../assets/bg/bg0.jpg')" :height="900">
      <div
        class="
          home
          text-center
          d-flex
          flex-column
          justify-md-space-between
          align-md-end
          pa-md-9 pa-2
          align-center
        "
      >
        <div class="text-center py-10 my-5" style="max-width: 1024px">
          <h1 class="text-md-h1 text-h2 font-weight-black">
            Supere seus objetivos
          </h1>
          <h4 class="text-md-h2 text-h4 my-5">
            Aqui você conta com a melhor assessoria esportiva e nutricional,
            especializada para conquistar o corpo e a saúde que deseja!
          </h4>
          <div class="mt-10">
            <BtnFaleConosco />
            <div class="d-flex justify-center ma-5">
              <v-icon size="40">mdi-circle-small</v-icon>
              <v-icon size="40">mdi-circle-small</v-icon>
              <v-icon size="40">mdi-circle-small</v-icon>
              <v-icon size="40">mdi-circle-small</v-icon>
              <v-icon size="40">mdi-circle-small</v-icon>
            </div>
          </div>
        </div>
        <FollowBanner />
      </div>
    </v-card>
    <v-toolbar
      tile
      class="d-flex justify-center align-center maingradient"
      dark
      height="80"
    >
      <template v-slot:img>
        <div class="d-flex align-center" v-if="!$vuetify.breakpoint.mobile">
          <v-img
            alt="DK Logo"
            class="shrink mx-10 my-1"
            contain
            src="@/assets/logo_full.png"
            transition="scale-transition"
            width="72"
          />
        </div>
      </template>
      <v-toolbar-title class="font-weight-black">
        <h2><strong>HOME</strong></h2>
      </v-toolbar-title>
    </v-toolbar>
    <v-parallax :src="require('@/assets/bg/bg1.jpg')" height="600" class="pa-0">
      <section class="d-flex flex-column flex-lg-row fill-height ma-md-15 ma-5">
        <div class="d-flex col-md-6 flex-column">
          <p
            class="text-lg-body-1 text-body-1"
            style="font-size: 18pt !important"
          >
            A DK Assessoria Esportiva atua há mais de <br />
            10 anos no mercado fitness e wellness no cenário nacional
          </p>
          <p
            class="text-lg-body-1 text-body-1 my-lg-10"
            style="font-size: 18pt !important"
          >
            Somos especializados em
            <strong
              class="text-uppercase"
              style="font-family: 'Bebas Neue'; font-size: 21pt"
              >dieta</strong
            >,
            <strong
              class="text-uppercase"
              style="font-family: 'Bebas Neue'; font-size: 21pt"
              >musculação</strong
            >
            e
            <strong
              class="text-uppercase"
              style="font-family: 'Bebas Neue'; font-size: 21pt"
              >treinamento funcional</strong
            >.<br />
            Nosso objetivo é o comprometimento em atingir os <br />
            resultados e a satisfação de nossos clientes.
          </p>
          <div
            class="d-flex align-center justify-space-around text-center pt-5"
          >
            <div>
              <v-avatar
                :size="$vuetify.breakpoint.mobile ? '90' : '160'"
                color="black"
              >
                <img
                  :src="require('@/assets/profile/Kaique1.jpg')"
                  alt="Kaique Henning"
                />
              </v-avatar>
              <p class="text-body-2 pt-5">
                <strong> Kaique Henning </strong> <br />
                <span class="text-caption">Personal Trainer</span> <br />
                <span class="text-caption">CREF: 020729-G/PR</span>
              </p>
            </div>
            <div>
              <v-avatar
                :size="$vuetify.breakpoint.mobile ? '90' : '160'"
                color="black"
              >
                <img
                  :src="require('@/assets/profile/Dani1.jpg')"
                  alt="Danieli Henning"
                />
              </v-avatar>
              <p class="text-body-2 pt-5">
                <strong> Danieli T. Henning </strong> <br />
                <span class="text-caption">Nutricionista</span> <br />
                <span class="text-caption">CRN: 8-8632</span>
              </p>
            </div>
          </div>
        </div>
        <div
          class="
            d-flex
            flex-column flex-md-row
            align-center align-md-end
            justify-center justify-md-end
          "
          style="width: 55%"
        >
          <BtnFaleConosco class="mx-md-5 my-2" left />
          <FollowBanner class="mx-md-5" v-if="!$vuetify.breakpoint.mobile" />
        </div>
      </section>
    </v-parallax>
  </div>
</template>
<script>
import BtnFaleConosco from "./BtnFaleConosco";
import FollowBanner from "./FollowBanner";

export default {
  components: { BtnFaleConosco, FollowBanner },
};
</script>

<style>
.home {
  width: 100% !important;
  height: 100% !important;
  top: -500px;
  background-color: transparent !important;
  background-image: url("../assets/bg/home-curve.png");
  background-position: top -170px right 0px !important;
}
</style>
