<template>
  <div>
    <v-toolbar
      tile
      class="d-flex justify-center align-center maingradient"
      dark
      height="80"
    >
      <template v-slot:img v-if="!$vuetify.breakpoint.mobile">
        <div class="d-flex align-center">
          <v-img
            alt="DK Logo"
            class="shrink mx-10 my-1"
            contain
            src="@/assets/logo_full.png"
            transition="scale-transition"
            width="72"
          />
        </div>
      </template>
      <v-toolbar-title class="font-weight-black">
        <h2><strong>RESULTADOS</strong></h2>
      </v-toolbar-title>
    </v-toolbar>
    <v-card
      height="720"
      class="d-flex flex-column align-center"
      :img="require('@/assets/bg/bg3.jpg')"
      dark
    >
      <v-sheet
        dark
        class="d-flex flex-column align-center justify-center whitegradient"
        height="620"
        width="100%"
      >
        <v-sheet width="100%" style="background: transparent" light>
          <v-carousel hide-delimiters cycle>
            <template
              v-slot:prev="{ on, attrs }"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="100"
                color="blue darken-4"
                style="background-color: transparent"
                >mdi-chevron-triple-left</v-icon
              >
            </template>
            <template
              v-slot:next="{ on, attrs }"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon v-bind="attrs" v-on="on" size="100" color="darken-4"
                >mdi-chevron-triple-right</v-icon
              >
            </template>
            <v-carousel-item v-for="(item, idx) in 5" :key="idx">
              <v-sheet
                class="d-flex fill-height align-center"
                style="background: transparent"
              >
                <div
                  class="
                    fill-height
                    d-flex
                    align-center
                    justify-center
                    mx-auto
                    pa-md-12 pa-2
                  "
                  :style="{ width: $vuetify.breakpoint.xs ? '100%' : '30%' }"
                >
                  <div
                    class="photoborder mx-5"
                    :style="{ width: $vuetify.breakpoint.xs ? '40%' : '60%' }"
                  >
                    <v-img
                      :src="require('@/assets/ad/antes/' + idx + '.jpeg')"
                      class="photo"
                    ></v-img>
                  </div>
                  <div
                    class="photoborder mx-5"
                    :style="{ width: $vuetify.breakpoint.xs ? '40%' : '60%' }"
                  >
                    <v-img
                      :src="require('@/assets/ad/depois/' + idx + '.jpeg')"
                      class="photo"
                    ></v-img>
                  </div>
                </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-sheet>
      </v-sheet>
      <section
        class="
          d-flex
          flex-column flex-md-row
          align-center
          justify-space-around
          ma-10
        "
        style="width: 90%"
      >
        <v-spacer></v-spacer>
        <BtnFaleConosco left class="float-right mx-md-5 my-2" />
        <FollowBanner
          class="float-right mx-md-5"
          v-if="!$vuetify.breakpoint.mobile"
        />
      </section>
    </v-card>
  </div>
</template>

<script>
import BtnFaleConosco from "@/components/BtnFaleConosco.vue";
import FollowBanner from "@/components/FollowBanner.vue";

export default {
  components: {
    FollowBanner,
    BtnFaleConosco,
  },
  data: () => ({
    ads: [
      {
        path: "@/assets/ad/antes0.png",
      },
    ],
  }),
};
</script>

<style>
.v-window__prev,
.v-window__next {
  background: none !important;
}

.photoborder {
  border-radius: 25px;
  background: linear-gradient(to right, #5789ad, #1f3b58);
  padding: 7px;
}

.photo {
  padding: 2rem;
  border-radius: 15px;
}
.whitegradient {
  background-color: transparent !important;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.35),
    rgba(255, 255, 255, 0.45),
    rgba(255, 255, 255, 0.45),
    rgba(255, 255, 255, 0.35)
  );
}
</style>
