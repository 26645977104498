<template>
  <div>
    <v-toolbar
      tile
      class="d-flex justify-center align-center maingradient"
      dark
      height="80"
    >
      <template v-slot:img v-if="!$vuetify.breakpoint.mobile">
        <div class="d-flex align-center">
          <v-img
            alt="DK Logo"
            class="shrink mx-10 my-1"
            contain
            src="@/assets/logo_full.png"
            transition="scale-transition"
            width="72"
          />
        </div>
      </template>
      <v-toolbar-title class="font-weight-black">
        <h2 v-if="!$vuetify.breakpoint.mobile">
          <strong>QUER RECEBER UMA AVALIAÇÃO GRATUITA?</strong>
        </h2>
        <h5 v-else class="text-center">
          <strong
            >QUER RECEBER <br />
            UMA AVALIAÇÃO GRATUITA?</strong
          >
        </h5>
      </v-toolbar-title>
    </v-toolbar>
    <v-parallax
      :src="require('@/assets/bg/bg4.jpg')"
      class="d-flex flex-column align-center"
      height="720"
    >
      <v-sheet
        dark
        class="
          d-flex
          flex-column
          text-center
          justify-center
          bluegradient
          align-center
          d-flex
        "
        width="100vw"
        style="background-color: transparent"
        height="420"
      >
        <v-icon size="60" class="ma-5">mdi-chevron-triple-down</v-icon>
        <v-card color="error" @click="contact()" style="border-radius: 100px">
          <h1 class="ma-8 text-lg-h3 font-weight-bold text-uppercase">
            AVALIAÇÃO GRATUITA
          </h1>
        </v-card>
        <v-avatar color="green" size="90" style="top: -40px" @click="contact()">
          <v-icon color="white" size="60">mdi-whatsapp</v-icon>
        </v-avatar>
      </v-sheet>
    </v-parallax>
    <v-toolbar
      tile
      class="d-flex justify-center align-center maingradient"
      dark
      height="80"
    >
      <template v-slot:img v-if="!$vuetify.breakpoint.mobile">
        <div class="d-flex align-center">
          <v-img
            alt="DK Logo"
            class="shrink mx-10 my-1"
            contain
            src="@/assets/logo_full.png"
            transition="scale-transition"
            width="72"
          />
        </div>
      </template>
      <v-toolbar-title class="font-weight-black">
        <h2><strong>GARANTIA</strong></h2>
      </v-toolbar-title>
    </v-toolbar>
    <v-card
      class="d-flex flex-column align-center justify-center"
      style="background: linear-gradient(to top, #1a243d, #2c465b)"
      height="900"
    >
      <v-sheet
        dark
        class="text-center d-flex flex-column align-center justify-center"
        style="background-color: transparent"
        width="100%"
        height="100%"
      >
        <div class="diagbars">
          <h1 class="text-uppercase text-lg-h3 text-h5 ma-5 font-weight-black">
            Satisfação garantida ou seu dinheiro de volta!<br />
          </h1>

          <h2 class="text-uppercase text-h6 font-weight-bold">
            A satisfação é garantida. você tem 7 dias após ter realizado o
            pagamento para desistir, se achar que não é para você!
          </h2>
          <img
            :src="require('@/assets/guarantee_seal.png')"
            class="ma-md-15"
            contain
            :height="$vuetify.breakpoint.mobile ? '200' : '300'"
          />
        </div>
        <v-sheet
          light
          width="100%"
          class="
            d-flex
            flex-md-row flex-column
            align-center
            justify-space-around
            pa-1
          "
        >
          <div class="col-2 d-flex justify-space-between">
            <v-img
              :src="require('@/assets/logo.png')"
              class="shrink"
              contain
              width="60"
            />
            <v-img
              :src="require('@/assets/securebanner.png')"
              class="shrink"
              contain
              width="120"
            />
          </div>
          <div class="d-flex disclaimer text-center col-md-4">
            DK ASSESSORIA ESPORTIVA<br />
            Termos de Uso e Políticas de Privacidade <br />
            Consulte o seu médico e siga todas as instruções de segurança antes
            de inicial qualquer programa de exercicio, plano nutricional ou
            utilizar qualquer produto de suplemento ou substitutos de refeições.
            <br />
            Especialmente se você estivar grávida, amamentando, tem qualquer
            cuidado médico ou está tomando qualquer tipo de medicamento.
          </div>
          <div class="d-flex align-center justify-space-between">
            <v-icon
              class="whatsfooter mx-auto pa-2 white--text"
              size="40"
              @click="contact()"
              >mdi-whatsapp</v-icon
            >
            <v-sheet
              class="d-flex flex-column justify-space-between"
              style="max-width: 250px; background: transparent"
            >
              <div class="d-flex justify-space-between">
                <v-btn
                  icon
                  x-small
                  href="https://www.instagram.com/dkassessoriaesportiva"
                  target="_blank"
                >
                  <v-icon size="20" class="mx-auto">mdi-instagram</v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-small
                  href="https://www.facebook.com/dkassessoriaesportiva"
                  target="_blank"
                >
                  <v-icon size="20" class="mx-auto"> mdi-facebook</v-icon>
                </v-btn>
              </div>
              <div>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
                <v-icon size="10">mdi-circle-small</v-icon>
              </div>
              <div>
                <strong class="float-right text-caption font-weight-bold"
                  >FOLLOW</strong
                >
              </div>
            </v-sheet>
          </div>
        </v-sheet>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    contact() {
      if (this.$vuetify.breakpoint.mobile) {
        window.open("whatsapp://send?phone=5541995450522");
      } else {
        window.open(
          "https://web.whatsapp.com/send?phone=5541995450522",
          "_blank"
        );
      }
    },
  },
};
</script>

<style>
.whatsfooter {
  background-color: green;
  border-radius: 50px;
}

.disclaimer {
  font-size: 7pt;
}

.bluegradient {
  background-color: transparent;
}

.diagbars {
  height: 70%;
  width: 100% !important;
  background-color: transparent !important;
  background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 4px,
      transparent 1px,
      rgba(50, 108, 250, 0.2) 7px
    ),
    linear-gradient(to bottom, transparent, transparent);
}
</style>
