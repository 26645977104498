<template>
  <div>
    <v-toolbar
      tile
      class="d-flex justify-center align-center maingradient"
      dark
      height="80"
    >
      <template v-slot:img>
        <div class="d-flex align-center" v-if="!$vuetify.breakpoint.mobile">
          <v-img
            alt="DK Logo"
            class="shrink mx-10 my-1"
            contain
            src="@/assets/logo_full.png"
            transition="scale-transition"
            width="72"
          />
        </div>
      </template>
      <v-toolbar-title class="font-weight-black">
        <h2><strong>CONSULTORIA</strong></h2>
      </v-toolbar-title>
    </v-toolbar>
    <v-card
      dark
      class="d-flex flex-column align-center justify-center"
      :img="require('@/assets/bg/bg2.jpg')"
      height="900"
    >
      <div
        class="
          text-center
          d-flex
          flex-column
          justify-md-space-between
          align-md-center
          pa-md-9 pa-2
          mt-5
          align-center
          bluegradient
        "
        style="width: 100%"
      >
        <div class="text-center py-10 my-5" style="max-width: 980px">
          <h1 class="text-md-h3 text-h5 font-weight-bold">
            * Planos que cabem no seu bolso *
          </h1>
          <h4
            class="text-md-h5 text-body-2 my-5"
            style="font-family: 'Montserrat' !important"
          >
            ESCOLHA O MELHOR PLANO DE TREINO <br />
            ADQUIRINDO UM PLANO, ENTRAREMOS EM CONTATO COM VOCÊ <br />
            PARA PREENCHERMOS A FICHA DE ANAMNASE E ASSIM <br />
            FAREMOS O PLANO ALIMENTAR QUE SE ADAPTA AO SEU CORPO E OBJETIVO.
          </h4>
          <BtnFaleConosco noicon />
        </div>
      </div>
      <div
        class="
          text-center
          d-flex
          flex-column
          justify-md-space-between
          align-md-center
          pa-md-9 pa-2
          align-center
          darkbluegradient
        "
        style="width: 100%"
      >
        <div class="text-center py-1 my-1">
          <h1 class="text-md-h2 text-h5 font-weight-black">Depoimentos</h1>
          <v-carousel
            hide-delimiters
            :show-arrows="false"
            cycle
            height="auto"
            class=""
          >
            <v-carousel-item
              v-for="depoimento in depoimentos"
              :key="depoimento.id"
            >
              <div
                class="
                  fill-height
                  d-flex
                  flex-column
                  align-end
                  justify-center
                  pa-5
                "
              >
                <q style="max-width: 980px" class="text-body-2"
                  >{{ depoimento.text }}
                </q>
                <span class="author justify-end"
                  >&mdash; {{ depoimento.author }}</span
                >
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
      <div
        class="fill-height d-flex align-center justify-end ma-5"
        style="width: 90%"
      >
        <BtnFaleConosco left />
        <FollowBanner class="float-right" />
      </div>
    </v-card>
  </div>
</template>
<script>
import BtnFaleConosco from "@/components/BtnFaleConosco.vue";
import FollowBanner from "@/components/FollowBanner.vue";

export default {
  components: {
    BtnFaleConosco,
    FollowBanner,
  },
  data: () => ({
    depoimentos: [
      {
        index: 0,
        text: "Melhor assessoria esportiva que tive até hoje. O plano alimentar é sempre na medida e o resultado dos treinos nunca deixaram a desejar.",
        author: "Arthur Garcete",
      },
      {
        index: 1,
        text: "Me chamo Vanessa tenho 30 anos e 2 filhas, quando fui atrás da DK foi pq minha autoestima estava horrível, então fiz consulta nutricional com acompanhamento, melhor escolha da minha vida. A Dani é maravilhosa de verdade, sabe nos mostrar o nosso melhor e está de prontidão para qualquer pergunta e dúvida.  Já no acompanhamento físico com o Kaique dá vontade de morrer mas vou falar que vale cada suor, cada ida para a academia com a força do ódio. Só tenho a agradecer a esse dois profissionais maravilhosos.",
        author: "Vanessa",
      },
      {
        index: 2,
        text: "Há pouco mais de um ano estou em acompanhamento com a DK.  No início meu objetivo era sair da obesidade, com o passar dos meses seguindo as orientações dos profissionais consegui atingi lo, porém no decorrer do caminho com todo o incentivo, orientações e motivações decidi que queria ir além de sair da obesidade,  transformei minha rotina do dia a dia em disciplina, determinação e constância. Indico estes profissionais a qualquer um que pergunta sobre minha trajetória  são profissionais ouvintes,inteligentes, carismáticos e verdadeiros profissionais, que amam a área em que atuam.Desejo todo o sucesso á vocês.",
        author: "Patrícia Flavia Nunes da Silva",
      },
      {
        index: 3,
        text: "Super atenciosa, me ajudou e me ajuda muito a manter o meu peso",
        author: "Sônia Sosa",
      },
      {
        index: 4,
        text: "Muito satisfeita e feliz com  a assessoria recebida, tendo resultados alcançados. Muito feliz com o progresso. Não poderia ser diferente com essa dupla dinâmica. Profissionais dedicados e atenciosos em tudo que fazem!",
        author: "Andreia e Celio",
      },
      {
        index: 5,
        text: 'A DK realiza um trabalho impecável, respeitando sempre a individualidade.  Dieta e treino específicos de acordo com o perfil e objetivo aliados a muito estudo e prática são a receita de sucesso desses maravilhosos, Dani e Kaique!!!  Eu tenho ficado admirada com meus resultados e isso é muito motivador!  Sempre quis ter uma barriga "tanquinho" e confesso que, após dois filhos, achei que seria impossível. Mas ta lá o abdômen aparecendo! Incrível!  São apenas 5 meses de assessoria.  Ver resultado em redução do percentual de gordura e aumento da massa magra é tudo que eu queria!!!  Profissionais incríveis, o melhor acompanhamento!❣️❣️❣️❣️',
        author: "Raphaela Freitas",
      },
    ],
  }),
};
</script>

<style>
.bluegradient {
  background: linear-gradient(
    to bottom,
    rgba(56, 116, 154, 0.7),
    rgba(56, 116, 154, 0.7)
  );
}
.darkbluegradient {
  background: linear-gradient(to right, #5789ad, #1f3b58);
}

q {
  font-family: "Montserrat", cursive;
}

.author {
  font-family: "Montserrat", cursive;
}
</style>
