<template>
  <div>
    <HomeAbertura />
    <HomeConsultoria />
    <HomeResultados />
    <HomeAvaliacao />
  </div>
</template>

<script>
import HomeAbertura from "@/components/HomeAbertura.vue";
import HomeConsultoria from "@/components/HomeConsultoria.vue";
import HomeResultados from "@/components/HomeResultados.vue";
import HomeAvaliacao from "@/components/HomeAvaliacao.vue";

export default {
  name: "Home",
  components: {
    HomeAbertura,
    HomeConsultoria,
    HomeResultados,
    HomeAvaliacao,
  },
};
</script>
