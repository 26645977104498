<template>
  <v-app>
    <v-app-bar app color="maingradient" dark hide-on-scroll height="80px">
      <div class="d-flex align-center" v-if="$vuetify.breakpoint.xs">
        <v-btn plain>
          <v-icon @click="drawer = !drawer">mdi-menu</v-icon>
        </v-btn>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
      <div class="d-flex align-center justify-center">
        <v-img
          alt="DK Logo"
          class="shrink mx-md-5"
          contain
          src="@/assets/logo_full.png"
          transition="scale-transition"
          width="72"
        />
      </div>
      <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
      <v-breadcrumbs
        v-if="!$vuetify.breakpoint.mobile"
        class="text-uppercase font-weight-bold"
        dark
        :divider="'⁞'"
      >
        <v-breacrumbs-item @click="$vuetify.goTo(0)">HOME</v-breacrumbs-item>
        <v-breadcrumbs-divider>⁞</v-breadcrumbs-divider>
        <v-breacrumbs-item @click="$vuetify.goTo(1800)"
          >CONSULTORIA</v-breacrumbs-item
        >
        <v-breadcrumbs-divider>⁞</v-breadcrumbs-divider>
        <v-breacrumbs-item @click="$vuetify.goTo(2800)"
          >RESULTADOS</v-breacrumbs-item
        >
        <v-breadcrumbs-divider>⁞</v-breadcrumbs-divider>
        <v-breacrumbs-item @click="$vuetify.goTo(0)"
          >ANAMNESE</v-breacrumbs-item
        >
      </v-breadcrumbs>
      <div class="mx-5" v-if="!$vuetify.breakpoint.mobile">
        <v-text-field
          class="pt-6"
          placeholder="Pesquisar"
          rounded
          single-line
          outlined
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </div>
      <!-- <div v-if="!$vuetify.breakpoint.mobile"> -->
      <!--   <v-btn text @click="$vuetify.goTo(0)" -->
      <!--     ><span class="font-weight-bold">Home</span></v-btn -->
      <!--   > -->
      <!--   <v-btn text @click="$vuetify.goTo(1386)" -->
      <!--     ><span class="font-weight-bold">Consultoria</span></v-btn -->
      <!--   > -->
      <!--   <v-btn text @click="$vuetify.goTo(2300)" -->
      <!--     ><span class="font-weight-bold">Resultados</span></v-btn -->
      <!--   > -->
      <!-- </div> -->
      <!-- <v-btn outlined> -->
      <!--   <span class="mr-2">Entrar</span> -->
      <!--   <v-icon>mdi-login</v-icon> -->
      <!-- </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item>
          <v-list-item-icon
            ><v-img
              shrink
              src="@/assets/logo_full.png"
              alt="DK Logo"
              transition="scale-transition"
              :max-width="34"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <span
              class="
                primary--text
                d-flex
                align-center
                text-h6
                font-weight-black
              "
              >Assessoria Esportiva</span
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$vuetify.goTo(0)">
          <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item>
        <v-list-item @click="$vuetify.goTo(1800)">
          <v-list-item-icon><v-icon>mdi-receipt</v-icon></v-list-item-icon>
          <v-list-item-content>Consultoria</v-list-item-content>
        </v-list-item>
        <v-list-item @click="$vuetify.goTo(2300)">
          <v-list-item-icon><v-icon>mdi-seal</v-icon></v-list-item-icon>
          <v-list-item-content>Resultados</v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="d-flex justify-space-around ma-4">
          <v-icon color="green">mdi-whatsapp</v-icon>
          <v-icon color="pink">mdi-instagram</v-icon>
          <v-icon color="blue">mdi-facebook</v-icon>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: false,
    nav: [
      { text: "Home" },
      { text: "Consultoria" },
      { text: "Resultados" },
      { text: "Anamnase" },
    ],
  }),
};
</script>
<style>
.maingradient {
  background-color: transparent !important;
  background: linear-gradient(to bottom, #284766, #588aae);
}
</style>
